import Emitter from 'tiny-emitter'
import get from 'lodash/get'
import cartSlice from './cartSlice'
import layoutSlice, { openCart } from './layoutSlice'
import * as gtag from '../lib/gtag'
import productsSlice from './productsSlice'
import { flatten } from 'lodash'
import isSampleProduct from '../helpers/isSampleProduct'
import {
  getSizeVariant,
  getColorVariant
} from '../helpers/productHelper'

const emitter = new Emitter()

export const addOnItemAddedListener = (fn) => {
  emitter.on('cart-item-added', fn)
  return () => {
    emitter.off('cart-item-added', fn)
  }
}

export const fetchCart = () => {
  const site = layoutSlice.state.site
  const { bigCommerce: { storeHash } } = site

  window.fetch('/api/bigcommerce/cart', {
    method: 'POST',
    body: JSON.stringify({
      storeHash,
      channelId: Number(site.bigCommerce.channelId)
    }),
    credentials: 'same-origin',
    mode: 'same-origin'
  })
    .then(res => res.json())
    .then(response => {
      setCartData(response)
    })
    .catch(error => {
      cartSlice.state.cartLoading = false
      cartSlice.state.cartError = error
    })
}

export const removeItemFromCart = (itemId, productDatalayerObject, currencyCode, variantId) => {
  const site = layoutSlice.state.site
  const { bigCommerce: { storeHash } } = site
  cartSlice.state.removingFromCart = variantId
  window.fetch(
    '/api/bigcommerce/cart/remove',
    {
      credentials: 'same-origin',
      mode: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        storeHash,
        itemId: itemId
      })
    }
  )
    .then(res => {
      gtag.event({
        action: 'remove_from_cart',
        label: 'Item removed from cart',
        value: itemId
      })
      if (productDatalayerObject) {
        gtag.trackProductRemoveFromCart([productDatalayerObject], currencyCode)
      }
      if (res.status === 204) {
        // 204 = No content. Everything has been removed from the cart.
        // We reinitialize an empty cart.
        return fetchCart()
      }
      return res.json()
    })
    .then(response => {
      response && setCartData(response)
    })
    .catch(error => {
      cartSlice.state.cartLoading = false
      cartSlice.state.removingFromCart = false
      cartSlice.state.addToCartError = error
    })
}

const updateItemInCart = (itemId, updatedItemData) => {
  const site = layoutSlice.state.site
  const { bigCommerce: { storeHash } } = site
  window.fetch(
    '/api/bigcommerce/cart/update',
    {
      credentials: 'same-origin',
      mode: 'same-origin',
      method: 'POST',
      body: JSON.stringify({
        storeHash,
        itemId,
        ...updatedItemData
      })
    }
  )
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        throw Error(res.statusText)
      }
    })
    .then(response => {
      setCartData(response)
    })
    .catch(error => {
      cartSlice.state.cartLoading = false
      cartSlice.state.addToCartError = { title: error.toString() }
    })
}

export const updateCartItemQuantity = (item, action, productDatalayerObject, currencyCode) => {
  const newQuantity = item.quantity + (action === 'minus' ? -1 : 1)
  cartSlice.state.updatingItem = item.id
  if (productDatalayerObject) {
    // only 1 item is added or removed at a time
    productDatalayerObject.quantity = '1'
    action === 'minus' ? gtag.trackProductRemoveFromCart([productDatalayerObject], currencyCode) : gtag.trackProductAddToCart([productDatalayerObject], currencyCode)
  }

  if (newQuantity < 1) {
    return removeItemFromCart(item.id)
  }
  let productVariantReferences = null

  if (typeof item.product_id !== 'undefined') {
    productVariantReferences = {
      product_id: item.product_id,
      variant_id: item.variant_id
    }
  }

  updateItemInCart(item.id, {
    line_item: {
      quantity: newQuantity,
      ...productVariantReferences
    }
  })
}

export const addToCart = async (lineItems = [], giftCertificates, retry, productDatalayerObjects, currencyCode, openCartOnAdd = true) => {
  const site = layoutSlice.state.site
  const { bigCommerce: { storeHash } } = site

  const products = productsSlice.state.products || []
  const isSample = flatten(products.map(({ product }) => product.variants)).find(({ id }) => id === lineItems[0].variantId)

  if (isSample) {
    const cartItems = cartSlice.state.cart.lineItems.physical_items || []
    const variantIds = cartItems.map((x) => x.variant_id)
    const samplesInCart = flatten(products.map(({ product }) => product.variants))
      .filter(({ id }) => variantIds.includes(id))
      .filter(variant => isSampleProduct(getSizeVariant(variant.option_values)?.label))

    // ensure only 5 samples are ever added to the cart
    if (samplesInCart.length >= 5) {
      return { ...cartSlice.state }
    }
  }

  const firstItem = get(lineItems, [0])
  if (firstItem) cartSlice.state.addingToCart = firstItem.variantId
  const parsedLineItems = lineItems?.map(item => {
    const parsed = {
      quantity: item.quantity,
      product_id: parseInt(item.productId, 10),
      variant_id: parseInt(item.variantId, 10),
      option_selections: []
    }

    if (item.option_selections) {
      parsed.option_selections = item.option_selections
    } else {
      parsed.variant_id = parseInt(item.variantId, 10)
    }

    const matchingProduct = products?.find(shopProduct => shopProduct.bigCommerceId === parsed.product_id)
    const productVariants = get(matchingProduct, 'product.variants', [])
    const matchingVariant = productVariants ? productVariants.find(variant => variant.id === parsed.variant_id) : null
    const variantColor = matchingVariant ? getColorVariant(matchingVariant.option_values)?.label : null
    const colorData = variantColor ? matchingProduct.colors.find(color => color.label === variantColor) : null
    if (matchingProduct?.title === 'Agra' && !matchingVariant?.option_values?.find(currOption => currOption.option_display_name === 'Size' && currOption.label === 'Sample')) {
      parsed.option_selections.push({
        option_id: matchingProduct?.locale === 'en-AU' ? 784 : 900,
        option_value: '\xa0'
      })
    }

    const preorderModifier = matchingProduct?.product?.modifiers?.find(modifier => modifier.name.toLowerCase().includes('preorder'))

    if (preorderModifier && (matchingProduct?.productPreorderable || colorData?.colorPreorderable)) {
      const preorderOption = {
        option_id: preorderModifier.id,
        option_value: '\xa0'
      }

      parsed.option_selections.push(preorderOption)
    } else {
      const matchingProductQuantity = matchingVariant?.inventory_level
      const onSale = !!matchingVariant?.sale_price

      const backorderModifier = matchingProduct?.product?.modifiers?.find(modifier => modifier.name.toLowerCase().includes('backorder'))
      const backorderOption = {
        option_id: backorderModifier?.id,
        option_value: '[Out of stock]'
      }

      const finalSaleModifier = matchingProduct?.product?.modifiers?.find(modifier => modifier.name === 'final_sale')
      const finalSaleOption = {
        option_id: finalSaleModifier?.id,
        option_value: '\xa0'
      }

      if (backorderModifier && matchingProductQuantity <= 1000 && matchingProductQuantity > 300) {
        parsed.option_selections.push(backorderOption)
      }

      if (finalSaleModifier && onSale) {
        parsed.option_selections.push(finalSaleOption)
      }
    }

    return parsed
  })

  try {
    const response = await window.fetch('/api/bigcommerce/cart/add', {
      method: 'POST',
      credentials: 'same-origin',
      mode: 'same-origin',
      body: JSON.stringify({
        storeHash,
        line_items: parsedLineItems,
        gift_certificates: giftCertificates
      })
    })
    if (response.ok) {
      const data = await response.json()
      emitter.emit('cart-item-added', lineItems)
      setCartData(data, firstItem?.productId)
      if (openCartOnAdd) openCart()

      if (productDatalayerObjects) {
        gtag.trackProductAddToCart(productDatalayerObjects, currencyCode)
      }

      gtag.event({
        action: 'add_to_cart',
        label: 'Item added to cart',
        value: lineItems || giftCertificates
      })
      cartSlice.state.addToCartError = null
      return { ...cartSlice.state }
    }
    if (response.status === 422) { // Business Rule Error
      const data = await response.json()
      cartSlice.state.addingToCart = false
      cartSlice.state.removingFromCart = false
      cartSlice.state.cartLoading = false
      cartSlice.state.updatingItem = false
      cartSlice.state.addToCartError = data
      console.log('422 error', data, data.title)
      return { ...cartSlice.state }
    }
    if (response.status === 404 && !retry) {
      // re-create a cart if cart was destroyed
      await window.fetch('/api/bigcommerce/cart', {
        method: 'POST',
        credentials: 'same-origin',
        mode: 'same-origin',
        body: JSON.stringify({
          storeHash: site.bigCommerce.storeHash,
          channelId: Number(site.bigCommerce.channelId)
        })
      })
      return await addToCart(lineItems, giftCertificates, true, productDatalayerObjects, currencyCode)
    }
    cartSlice.state.addingToCart = false
    cartSlice.state.addToCartError = { title: response.statusText, status: response.status }
    return { ...cartSlice.state }
  } catch (error) {
    cartSlice.state.addingToCart = false
    cartSlice.state.addToCartError = { title: error.toString() }
    return { ...cartSlice.state }
  }
}

const setCartData = (response, productId) => {
  if (response.status === 204 || response.status === 404) {
    cartSlice.state.cartLoading = false
  } else {
    const responseLineItems = get(response, 'data.line_items', {
      physical_items: [],
      digital_items: [],
      custom_items: [],
      gift_certificates: []
    })
    const cartAmount = get(response, 'data.cart_amount', 0)
    const baseAmount = get(response, 'data.base_amount', 0)
    const discountAmount = get(response, 'data.discount_amount', 0)
    const taxIncluded = get(response, 'data.tax_included', false)
    const currency = get(response, 'data.currency', { code: 'AUD' })
    const redirectUrls = get(response, 'data.redirect_urls')
    const lineItems = responseLineItems

    cartSlice.state.addedToCart = productId || false
    cartSlice.state.addingToCart = false
    cartSlice.state.removingFromCart = false
    cartSlice.state.cartLoading = false
    cartSlice.state.updatingItem = false
    cartSlice.state.cart = {
      currency,
      cartAmount,
      baseAmount,
      discountAmount,
      taxIncluded,
      lineItems,
      numberItems:
        lineItems.physical_items.length +
        lineItems.digital_items.length +
        lineItems.custom_items.length +
        lineItems.gift_certificates.length,
      redirectUrls
    }
  }
}